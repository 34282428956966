import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { isSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";

import {
  ContactWrapper,
  MainWrapper,
  Side,
  PaddingBetweenWrapper,
  CloseModalWrapper,
  ContactTitle,
  SectionTitle,
  SectionDivider,
  SectionDescription,
  ImageDescription,
  RowWrapper,
  LinkWrapper,
} from "./style";
import Breakpoint from "../../Breakpoint";
import BackgroundSlider from "../../ImageSlider";
import CloseIcon from "-!svg-react-loader!../../../../assets/images/closeIcon.svg";
import FacebookIcon from "-!svg-react-loader!../../../../assets/images/contact/facebook.svg";
import LinkedInIcon from "-!svg-react-loader!../../../../assets/images/contact/linkedin.svg";
import YouTubeIcon from "-!svg-react-loader!../../../../assets/images/contact/youtube.svg";

interface ImageDescriptionType {
  name: string;
  description: string;
}

interface ContactProps {
  transitionOpen: boolean;
  handleClose: () => void;
}

const Contact: React.FC<ContactProps> = ({ transitionOpen, handleClose }) => {
  const [slide, setSlide] = useState<number>(0);

  const { t } = useTranslation("contact");
  const data = useStaticQuery(backgroundQuery);
  const descriptions = (t(
    `background_images`
  ) as unknown) as ImageDescriptionType[];
  const currentDescription = descriptions.filter(
    ({ name }) => data.allFile && name === data.allFile.nodes[slide].name
  )[0];

  return (
    <ContactWrapper open={transitionOpen ? 1 : 0}>
      <Breakpoint device="laptop">
        <MainWrapper>
          <BackgroundSlider
            images={data.allFile.nodes}
            current={slide}
            setCurrent={setSlide}
            fullscreen
          />
        </MainWrapper>
      </Breakpoint>

      <Side autoHeight={isSafari ? 1 : 0}>
        <CloseModalWrapper>
          <CloseIcon onClick={handleClose} />
        </CloseModalWrapper>

        <Breakpoint device="mobile">
          <ContactTitle>{t(`contact_title`)}</ContactTitle>
        </Breakpoint>

        <PaddingBetweenWrapper noScroll={isSafari ? 1 : 0}>
          <div>
            <SectionTitle>{t(`oke_title`)}</SectionTitle>
            <SectionDivider />
            <SectionDescription>ul. Jana z Kolna 11</SectionDescription>
            <SectionDescription>80-864 Gdańsk</SectionDescription>
            <SectionDescription>+48 58 321 72 47</SectionDescription>
            <SectionDescription>
              {
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href="mailto:office@oke.pl"
                >
                  office@oke.pl
                </a>
              }
            </SectionDescription>          
          </div>

          <div>
            <SectionTitle>{t(`elblag_title`)}</SectionTitle>
            <SectionDivider />
            <SectionDescription>ul. Zacisze 4C</SectionDescription>
            <SectionDescription>82-300 Elbląg</SectionDescription>
            <SectionDescription>
              {
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href="mailto:elblag@oke.pl"
                >
                  elblag@oke.pl
                </a>
              }
            </SectionDescription>
          </div>
          <div>
            <SectionTitle>{t(`recruitment`)}</SectionTitle>
            <SectionDescription>
              {
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href="mailto:rekrutacja@oke.pl"
                >
                  rekrutacja@oke.pl
                </a>
              }
            </SectionDescription>
          </div>

          <Breakpoint device={["mobile", "tablet", "tabletLandscape"]}>
            <RowWrapper>
              <a
                href="https://facebook.com/okepoland/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://linkedin.com/company/oke-poland"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
              <a
                href="https://www.youtube.com/channel/UC40pBzWzKArfXKg-q9_irhg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YouTubeIcon />
              </a>
            </RowWrapper>
          </Breakpoint>
        </PaddingBetweenWrapper>

        <Breakpoint device="computer">
          <div>
            <RowWrapper>
              <LinkWrapper>
                <a
                  href="https://facebook.com/okepoland/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookIcon />
                </a>
              </LinkWrapper>
              <LinkWrapper>
                <a
                  href="https://linkedin.com/company/oke-poland"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedInIcon />
                </a>
              </LinkWrapper>
              <LinkWrapper>
                <a
                  href="https://www.youtube.com/channel/UC40pBzWzKArfXKg-q9_irhg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <YouTubeIcon />
                </a>
              </LinkWrapper>
            </RowWrapper>
            <SectionDivider noTopMargin={1} />
            <ImageDescription>
              {currentDescription && currentDescription.description}
            </ImageDescription>
          </div>
        </Breakpoint>
      </Side>
    </ContactWrapper>
  );
};

const backgroundQuery = graphql`
  {
    allFile(
      filter: { relativeDirectory: { eq: "contactBackground" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;

export default Contact;
